"use client";
import Link from "next/link";
import Image from "next/image";
import FlipHover from "./Animations/FlipHover";
import { Fragment, useState } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
const CTAButton = ({
  text,
  href,
  className,
  arrowSize,
  onClick,
  mr = false,
}: {
  text: string;
  href?: string;
  className?: string;
  arrowSize?: number;
  onClick?: (e: any) => void;
  mr?: boolean;
}) => {
  const [hovered, setHover] = useState(false);
  return (
    <CTAWrapper
      setHover={setHover}
      onClick={onClick}
      href={href}
      className={className}
    >
      {text.split("\n").map((char, index) => (
        <FlipHover
          //invert={index % 2 == 0}
          hovered={hovered}
          key={`${index}_${char}`}
          className="mt-auto"
        >
          {char.trim()}
        </FlipHover>
      ))}
      <motion.div
        className={classNames("absolute", "top-small", {
          "right-0": !mr,
          "right-4": mr,
        })}
        animate={hovered ? "hovered" : "initial"}
        variants={{
          hovered: {
            y: 16,
            x: -16,
            scale: 1.2,
          },
          inital: {
            y: 0,
            x: 0,
            scale: 1,
          },
        }}
      >
        <Image
          height={arrowSize || 24}
          width={arrowSize || 24}
          className={classNames("sm:h-auto h-4")}
          alt="Arrow Icon"
          src={"./assets/Arrow.svg"}
        />
      </motion.div>
    </CTAWrapper>
  );
};

const CTAWrapper = ({
  children,
  setHover,
  className,
  href,
  onClick,
}: {
  children: React.ReactNode;
  setHover: (arg: boolean) => void;
  className?: string;
  href?: string;
  onClick?: (arg: any) => void;
}) => {
  if (href === undefined && onClick === undefined)
    return <>Please provide link or function to button.</>;
  return (
    <Fragment>
      {!(href === undefined) && (
        <Link
          href={href || ""}
          className={classNames(
            className,
            "xl:text-4xl",
            "md:text-3xl",
            "sm:text-xl",
            "text-lg",
            "p-4 relative"
          )}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {children}
        </Link>
      )}
      {!(onClick === undefined) && (
        <div
          className={classNames(
            className,
            "cursor-pointer",
            "xl:text-4xl",
            "md:text-3xl",
            "sm:text-xl",
            "text-lg",
            "p-4 relative"
          )}
          onClick={onClick}
          role="button"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              onClick(e);
            }
          }}
          tabIndex={0}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {children}
        </div>
      )}
    </Fragment>
  );
};

export default CTAButton;
