"use client";
import { pushToDataLayer } from "@/utils/analytics/pushToDataLayer";
import classNames from "classnames";
import React, { useEffect } from "react";

declare global {
  interface Window {
    Calendly?: {
      initPopupWidget: (url: string | Record<string, any>) => void;
    };
    open(
      url?: string | URL | undefined,
      target?: string | undefined,
      features?: string | undefined
    ): Window | null;
  }
}

interface CalendlyButtonProps {
  children: React.ReactNode;
  className?: string;
}

const CalendlyButton: React.FC<CalendlyButtonProps> = ({
  children,
  className,
}) => {
  const CALENDLY_URL = process.env.NEXT_PUBLIC_CALLENDLY_LINK;

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (typeof window !== "undefined") {
      const handleEventScheduled = () => {
        pushToDataLayer("calendly_booking", {});
        window.removeEventListener(
          "calendly.event_scheduled",
          handleEventScheduled
        );
      };
      window.addEventListener("calendly.event_scheduled", handleEventScheduled);
      if (window.Calendly !== undefined) {
        window.Calendly.initPopupWidget({
          url: `${CALENDLY_URL}?hide_gdpr_banner=1`,
          prefill: {},
          hideEventTypeDetails: true,
          hideGdprBanner: true,
        });
      } else if (CALENDLY_URL) {
        window.open(CALENDLY_URL, "_blank", "noopener,noreferrer");
      }
      pushToDataLayer("callendly_click", {});
    }
  };

  return (
    <button
      aria-label="Open Calendly booking window"
      onClick={handleClick}
      className={classNames(className, "cursor-pointer flex text-left")}
    >
      {children}
    </button>
  );
};

export default CalendlyButton;
