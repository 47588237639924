"use client";

import * as THREE from "three";
import { useEffect, useRef, useState } from "react";
import { projectMouse } from "@/utils/threeJS";
import { debounce } from "@/utils/utils";
import { getCanvasEdges } from "./src/utilities";
import { IServicesSceneProps, Dimensions, SceneRef } from "./src/types";
import { BREAKPOINT_PROPERTIES } from "./src/consts";
import { getBreakpoint } from "./src/utilities";
import {
  configureScene,
  setupParticles,
  setupTiles,
} from "./src/configureScene";

const useVisibilityCheck = (
  containerRef: React.RefObject<HTMLDivElement>,
  threshold = 0.2
) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkInitialVisibility = (element: HTMLElement) => {
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const visibleHeight =
        Math.min(windowHeight, rect.bottom) - Math.max(0, rect.top);
      const visiblePercentage = visibleHeight / rect.height;
      return visiblePercentage >= threshold;
    };

    if (containerRef.current) {
      // Set initial visibility
      setIsVisible(checkInitialVisibility(containerRef.current));

      // Setup intersection observer for scroll updates
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          // Add a small delay to ensure proper cleanup before state update
          setTimeout(() => {
            setIsVisible(entry.isIntersecting);
          }, 50);
        },
        { threshold }
      );

      observer.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }
  }, [threshold]);

  return [isVisible, setIsVisible] as const;
};

const animateScene = (
  scene: SceneRef,
  isVisible: boolean,
  setTileIndex: (index: number) => void
) => {
  if (!scene || !isVisible) return;

  try {
    const elapsedTime = scene.clock.getElapsedTime();
    scene.raycaster.setFromCamera(scene.mouse, scene.camera);

    scene.tiles.calculateMovement(scene.raycaster);
    setTileIndex(scene.tiles.active);

    // Safely update particles with null checks
    if (
      scene.particles?.material?.uniforms &&
      scene.gpgpu?.computation &&
      scene.gpgpu.particlesVariable
    ) {
      scene.particles.material.uniforms.uParticlesTexture.value =
        scene.gpgpu.computation.getCurrentRenderTarget(
          scene.gpgpu.particlesVariable
        ).texture;
      scene.particles.material.uniforms.uTime.value = elapsedTime;
      scene.gpgpu.particlesVariable.material.uniforms.uTime.value = elapsedTime;

      const mouseXYZ = projectMouse(scene.raycaster, 0, 0);
      scene.gpgpu.particlesVariable.material.uniforms.uMouse.value =
        new THREE.Vector2(mouseXYZ.x, mouseXYZ.y);

      scene.gpgpu.computation.compute();
    }

    if (scene.renderer && scene.scene && scene.camera) {
      scene.renderer.render(scene.scene, scene.camera);
    }

    scene.animationFrameId = window.requestAnimationFrame(() =>
      animateScene(scene, isVisible, setTileIndex)
    );
  } catch (error) {
    console.warn("Animation error:", error);
    // Cancel animation frame on error to prevent cascading failures
    if (scene.animationFrameId) {
      window.cancelAnimationFrame(scene.animationFrameId);
    }
  }
};

const createEventHandlers = (
  container: HTMLDivElement,
  mouse: THREE.Vector2
) => {
  const updatePointerPosition = (x: number, y: number) => {
    const canvas = container.querySelector("canvas");
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const normalizedX = ((x - rect.left) / canvas.width) * 2 - 0.5;
    const normalizedY = -((y - rect.top) / canvas.height) * 4 + 1;
    mouse.x = normalizedX * 2;
    mouse.y = normalizedY;
  };

  return {
    handleMouseMove: ((event: MouseEvent) => {
      requestAnimationFrame(() =>
        updatePointerPosition(event.clientX, event.clientY)
      );
    }) as EventListener,
    handleTouchMove: ((event: TouchEvent) => {
      event.preventDefault();
      const touch = event.touches[0];
      if (touch) {
        requestAnimationFrame(() =>
          updatePointerPosition(touch.clientX, touch.clientY)
        );
      }
    }) as EventListener,
    handleTouchStart: ((event: TouchEvent) => {
      event.preventDefault();
      const touch = event.touches[0];
      if (touch) {
        requestAnimationFrame(() =>
          updatePointerPosition(touch.clientX, touch.clientY)
        );
      }
    }) as EventListener,
    handlePointerLeave: (() => {
      requestAnimationFrame(() => {
        mouse.x = 10;
        mouse.y = 10;
      });
    }) as EventListener,
  };
};

const cleanupScene = (scene: SceneRef, container: HTMLDivElement) => {
  if (!scene) return;

  // Cancel animation frame first
  if (scene.animationFrameId) {
    window.cancelAnimationFrame(scene.animationFrameId);
  }

  // Remove event listeners
  if (scene.eventHandlers) {
    Object.entries(scene.eventHandlers).forEach(([event, handler]) => {
      const eventName = event.toLowerCase().replace("handle", "");
      container.removeEventListener(eventName, handler);
    });
  }

  // Remove canvas
  const canvas = container.querySelector("canvas");
  if (canvas) {
    container.removeChild(canvas);
  }

  // Dispose of Three.js resources
  try {
    if (scene.scene) {
      scene.scene.traverse((object) => {
        if (object instanceof THREE.Mesh) {
          if (object.geometry) object.geometry.dispose();
          if (Array.isArray(object.material)) {
            object.material.forEach((material) => material?.dispose());
          } else if (object.material) {
            object.material.dispose();
          }
        }
      });
    }

    if (scene.particles) {
      if (scene.particles.geometry) scene.particles.geometry.dispose();
      if (scene.particles.material) scene.particles.material.dispose();
    }

    if (scene.renderer) {
      scene.renderer.dispose();
      scene.renderer.forceContextLoss();
    }

    if (scene.gpgpu?.computation) {
      scene.gpgpu.computation.dispose();
    }
  } catch (error) {
    console.warn("Cleanup error:", error);
  }
};

const ServicesScene = ({
  fullScreen = false,
  updateService,
  services,
  indexRef,
}: IServicesSceneProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<SceneRef>(null);
  const [tileIndex, setTileIndex] = useState(0);
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const [isVisible, setIsVisible] = useVisibilityCheck(containerRef);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (sceneRef.current && containerRef.current) {
        cleanupScene(sceneRef.current, containerRef.current);
        sceneRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;

    const updateDimensions = debounce(() => {
      if (!containerRef.current) return;

      const width = fullScreen
        ? window.innerWidth
        : containerRef.current.clientWidth;
      const height = fullScreen
        ? window.innerHeight
        : containerRef.current.clientHeight;

      setDimensions((prev) => {
        if (prev.width !== width || prev.height !== height) {
          // Cleanup before updating dimensions
          if (sceneRef.current) {
            cleanupScene(sceneRef.current, containerRef.current!);
            sceneRef.current = null;
          }
          return { width, height };
        }
        return prev;
      });
    }, 200);

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      updateDimensions.cancel();
    };
  }, [fullScreen]);

  useEffect(() => {
    if (
      !containerRef.current ||
      dimensions.width === 0 ||
      dimensions.height === 0
    )
      return;
    if (sceneRef.current) return;

    try {
      const mouse = new THREE.Vector2(100, 100);
      const breakPointProperty = BREAKPOINT_PROPERTIES[getBreakpoint()];

      const { scene, camera, renderer, sizes, aspect, cameraPos } =
        configureScene(dimensions);

      const edges = getCanvasEdges(
        renderer,
        camera as THREE.PerspectiveCamera,
        scene,
        cameraPos.z
      );

      const { particles, gpgpu } = setupParticles(
        edges,
        renderer,
        sizes,
        breakPointProperty.particleDensity
      );
      scene.add(particles.points);

      const tiles = setupTiles(
        services,
        edges,
        camera,
        containerRef.current,
        sizes,
        aspect,
        breakPointProperty,
        indexRef.current
      );

      tiles.addToScene(scene);
      tiles.setIndex(indexRef.current);

      const eventHandlers = createEventHandlers(containerRef.current, mouse);
      containerRef.current.appendChild(renderer.domElement);

      sceneRef.current = {
        scene,
        camera,
        renderer,
        particles,
        gpgpu,
        tiles,
        animationFrameId: 0,
        mouse,
        raycaster: new THREE.Raycaster(),
        clock: new THREE.Clock(),
        eventHandlers,
      };
    } catch (error) {
      console.error("Scene setup error:", error);
    }
  }, [dimensions, services, indexRef]);

  useEffect(() => {
    const scene = sceneRef.current;
    if (!scene || !containerRef.current) return;

    let isAnimating = false;

    if (isVisible && !isAnimating) {
      isAnimating = true;
      Object.entries(scene.eventHandlers).forEach(([event, handler]) => {
        const eventName = event.toLowerCase().replace("handle", "");
        containerRef.current!.addEventListener(eventName, handler, {
          passive: event.includes("Touch") ? false : true,
        });
      });
      animateScene(scene, isVisible, setTileIndex);
    }

    return () => {
      isAnimating = false;
      if (scene) {
        if (scene.animationFrameId) {
          window.cancelAnimationFrame(scene.animationFrameId);
        }
        Object.entries(scene.eventHandlers).forEach(([event, handler]) => {
          const eventName = event.toLowerCase().replace("handle", "");
          if (containerRef.current) {
            containerRef.current.removeEventListener(eventName, handler);
          }
        });
      }
    };
  }, [isVisible]);

  return (
    <div
      aria-hidden="true"
      id="three-js-two"
      ref={containerRef}
      className="flex w-full h-full max-w-[1920px]"
      onMouseMove={() => updateService(tileIndex)}
      onTouchEnd={() => updateService(tileIndex)}
    />
  );
};

export default ServicesScene;
