import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

interface IThreeInit {
  width: number;
  height: number;
  cameraType: "perspective" | "orthographic"; // 'perspective' or 'orthographic'
  fov: number; // Field of view
  near: number; // Near clipping plane
  far: number; // Far clipping plane
  aspectRatio: number;
  backgroundColor?: string; // Background color of the scene
  backgroundOpacity?: number;
  enableControls?: boolean;
}

const getThreeJSComponents = ({
  width,
  height,
  cameraType,
  fov,
  near,
  far,
  backgroundColor,
  backgroundOpacity,
  enableControls,
}: IThreeInit) => {
  const scene = new THREE.Scene();
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setClearColor(backgroundColor || "#FFF", backgroundOpacity || 0);
  renderer.setSize(width, height);
  let camera;
  const aspect = width / height;
  if (cameraType === "perspective") {
    camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  } else {
    camera = new THREE.OrthographicCamera(
      -aspect * 10,
      aspect * 10,
      10,
      -10,
      near,
      far
    );
  }
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enabled = !!enableControls;
  return {
    scene: scene,
    camera: camera,
    renderer: renderer,
    controls: controls,
  };
};

const resizeCanvas = (
  renderer: THREE.WebGLRenderer,
  containerRef: HTMLDivElement | null,
  stateFunction: (x: number, y: number) => void
) => {
  return () => {
    if (containerRef === null) return;
    const width = containerRef.clientWidth;
    const height = containerRef.clientHeight;
    stateFunction(width, height);
    renderer.setSize(width, height);
  };
};

export { resizeCanvas, getThreeJSComponents };

export const projectMouse = (
  raycaster: THREE.Raycaster,
  planeConstant = 0,
  shift = 0
) => {
  const planeNormal = new THREE.Vector3(0, 0, 1); // Normal pointing in the Z direction
  const ray = raycaster.ray; // Get the ray from the raycaster
  const intersectionPoint = new THREE.Vector3();
  const directionDotNormal = ray.direction.dot(planeNormal);
  if (Math.abs(directionDotNormal) > 0) {
    const t =
      (planeConstant + shift - ray.origin.dot(planeNormal)) /
      directionDotNormal;
    if (t >= 0) {
      intersectionPoint.copy(ray.origin).addScaledVector(ray.direction, t);
    }
  }
  return intersectionPoint;
};

export function htmlToScenePosition(
  x: number,
  y: number,
  width: number,
  height: number,
  camera: THREE.PerspectiveCamera | THREE.OrthographicCamera
) {
  // Convert mouse coordinates to normalized device coordinates (-1 to 1)
  const ndcX = (x / width) * 2 - 1;
  const ndcY = -(y / height) * 2 + 1;

  // Create vector for conversion
  const vector = new THREE.Vector3(ndcX, ndcY, -1);
  vector.unproject(camera);

  // Get direction from camera to point
  const dir = vector.sub(camera.position).normalize();

  // Distance to z=0 plane
  const distance = -camera.position.z / dir.z;

  const finalPos = camera.position.clone().add(dir.multiplyScalar(distance));

  // Final position
  return new THREE.Vector3(
    finalPos.x, // roundToThousandth(finalPos.x),
    finalPos.y,
    0
  );
}
