'use client'
import CTAButton from "@/components/CTAButton";
import SiteLogo from "@/components/SiteLogo";
import classNames from "classnames";
import { ValueProposition } from "@/components/ValueProposition";
import { TypedObject } from "sanity";
import { PortableText } from "next-sanity";

export interface ITextCTAColumn {
  className?: string;
  logoSubtext: string;
  aboutBodyRaw: TypedObject | TypedObject[];
  ctaSection: {
    href: string;
    text: string;
  };
}

const TextCTAColumn = ({
  className,
  aboutBodyRaw,
  logoSubtext,
  ctaSection,
}: ITextCTAColumn) => {
  return (
    <div className={classNames(className, "flex flex-col rounded-l-4xl")}>
      <SiteLogo className="bg-gradient-to-b from-neutral to-transparent mb-small " logoSubtext={logoSubtext}/>
      <div className="flex flex-col font-monument-light text-base  pt-small px-base h-full py-small border-t-[0.5px] border-black">
        <div className="lg:text-lg lg:gap-9 md:text-md md:gap-7 sm:text-base text-sm gap-4 flex flex-col font-light sm:mb-0 mb-base"><PortableText value={aboutBodyRaw}/></div>
        <ValueProposition
          className={classNames(
            "lg:hidden flex !relative sm:my-auto my-8 border-black w-fit py-small h-fit"
          )}
          text={`Little \nshovels \ntowards \nyour goal.`}
        />
      </div>
      <CTAButton
        mr
        text={ctaSection.text}
        className="lg:sticky lg:bottom-0 bg-gradient-to-t from-neutral to-transparent border-t-[0.5px] border-black mt-auto"
        onClick={() => {
          document.getElementById("contact-section")?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }}
      />
    </div>
  );
};

export default TextCTAColumn;
