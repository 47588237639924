import classNames from "classnames";
import { TypedObject } from "sanity";
import { PortableText, PortableTextComponents } from "next-sanity";

export const ServicesInfo = ({
  services,
  className,
  currentIndex,
  setIndex,
}: {
  services: {
    title: string;
    textRaw: TypedObject | TypedObject[];
  }[];
  CTA: { href: string; text: string };
  className?: string;
  currentIndex: number;
  setIndex: (index: number) => void;
}) => {
  const components: PortableTextComponents = {
    block: {
      normal: ({ children, index }) => {
        if (index === 0) {
          return (
            <p className="block items-center">
              <span className="inline-block">
                <span className="flex flex-row font-normal whitespace-nowrap">
                  <span className="arrowTarget border-[2px] border-primary border-l-0 border-b-0 rounded-tr text-black  px-1 -mt-[2px] pr-2 mr-2">
                    {services[currentIndex].title}
                  </span>
                </span>
              </span>
              {children}
            </p>
          );
        }
        return <>{children?.toString().length ? <p>{children}</p> : ""}</>;
      },
    },
    marks: {
      link: ({ children }) => (
        <button
          className="cursor-pointer text-primary"
          onClick={() => {
            document.getElementById("contact-section")?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }}
           aria-label="Scroll to contact form"
        >
          {children}
        </button>
      ),
    },
  };
  return (
    <div className={classNames(className, "z-10 w-full flex")}>
      <div
        className="w-full font-light"
        role="tabpanel"
        id={`service-content-${currentIndex}`}
        aria-labelledby={`service-tab-${currentIndex}`}
      >
        <div className="gap-2 flex flex-col w-full">
          <PortableText
            value={services[currentIndex].textRaw}
            components={components}
          />
        </div>
        <div
          className="sr-only"
          role="tablist"
          aria-label="Services navigation"
        >
          {services.map(({ title }, index) => (
            <button
              key={`${title}_${index}`}
              onClick={() => setIndex(index)}
              role="tab"
              aria-selected={currentIndex === index}
              aria-controls={`service-content-${index}`}
              tabIndex={0}
            >
              {title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesInfo;
