import classNames from "classnames";
import Typewrite from "./Animations/Typewrite";

export const ValueProposition = ({
    text,
    className,
  }: {
    text: string;
    className?: string;
  }) => {
    return (
      <div
        className={classNames(
          className,
          "flex absolute",
          "lg:text-4xl",
          "md:text-3xl",
          "sm:text-2xl",
          "text-xl",
          "text-black"
        )}
      >
        <Typewrite text={text}/>
      </div>
    );
  };