import { projectMouse } from "@/utils/threeJS";
import { splitTextIntoTwoLines } from "@/utils/utils";
import * as THREE from "three";
// @ts-expect-error fuck you
import { Text } from "troika-three-text";
import {calculatePath} from './utilities';
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import { createNoise3D } from 'simplex-noise';

interface ConstructorOptions {
  tiles: string[];
  offset: number;
  serviceIndex: number;
  renderLines?: boolean;
  tilesPositions: THREE.Vector3[];
  tileScale: number;
  targetPoint: THREE.Vector3;
}

export default class InteractiveTiles {
  private noise3D: ReturnType<typeof createNoise3D>;
  private time: number = 0;


  private readonly noiseScale = 0.15; // Adjust this to control noise intensity
  private readonly noiseSpeed = 0.001; //
  public positions: THREE.Vector3[];
  public meshes: THREE.Mesh[];
  public linesArray: THREE.Vector3[];
  public lines: Line2 | null = null;
  public arrow: Line2 | null = null;
  public active: number;
  public activePosition = new THREE.Vector3(0, 0, 0);
  textAdjustment = 0.03 * this.options.tileScale;
  lineGeometry: LineGeometry | null = null;
  arrowGeometry: LineGeometry | null = null;
  lineMaterial: LineMaterial | null = null;
  arrowMaterial: LineMaterial | null = null;
  text: Text[];
  vertexShader = `
    varying vec2 vUv;
    void main() {
        vUv = uv;
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        gl_Position = projectionMatrix * mvPosition;
    }
    `;
  fragmentShader = `
    varying vec2 vUv;
    uniform float uActive;
    vec2 rectMin = vec2(0.,0.);
    vec2 rectMax = vec2(1.,1.);
    float edgeThickness = 0.1;
    void main() {
      vec2 uv = vUv;
      float isOnEdge = 0.0;

      if (abs(uv.x - rectMin.x) < edgeThickness || abs(uv.x - rectMax.x) < edgeThickness){
          isOnEdge = 1.0;
      }
    
      if (abs(uv.y - rectMin.y) < edgeThickness || abs(uv.y - rectMax.y) < edgeThickness){
          isOnEdge = 1.0;
      }
  
      if (isOnEdge > 0.0 || uActive == 1.0){
        gl_FragColor = vec4(0.688, 0.,0., 1.);
      } else{
        discard; 
      }
    } 
    `;
  constructor(public options: ConstructorOptions) {
    this.active = 0;
    this.positions = this.options.tilesPositions;
    this.activePosition = this.positions[0];
    this.meshes = this.initMeshes(this.options.tilesPositions);
    this.linesArray = this.calculateLinesArray(this.positions);
    this.lines = options.renderLines ? this.initLines() : null;
    this.arrow = this.initArrow();
    this.noise3D = createNoise3D();
    this.text = options.tiles.map((x, index) => {
      const text = new Text();
      const position = options.tilesPositions[index];
      text.font = "/ABCMonumentGroteskMono-Medium-Trial.woff";
      text.text = splitTextIntoTwoLines(`[${x.toUpperCase()}]`);
      text.fontSize = 0.05 * options.tileScale;
      text.color = "#000000";
      text.position.x = position.x;
      text.position.y = position.y;
      text.sync();
      return text;
    });
  }

  initMeshes = (pos: THREE.Vector3[]) => {
    return pos.map(({ x, z }) => {
      const planeMaterial = new THREE.ShaderMaterial({
        vertexShader: this.vertexShader,
        fragmentShader: this.fragmentShader,
        uniforms: {
          uActive: { value: this.options.serviceIndex },
        },
      });
      const plane = new THREE.PlaneGeometry(
        0.075 * this.options.tileScale,
        0.075 * this.options.tileScale,
        1,
        1
      );
      plane.rotateZ(Math.PI/180 * 45)
      const mesh = new THREE.Mesh(plane, planeMaterial);
      mesh.position.setX(x);
      mesh.position.setZ(z);
      return mesh;
    });
  };

  calculateLinesArray = (positions: THREE.Vector3[]) => {
    let linesArray = [...Array(positions.length * 2 - 1)].map(
      (vector, index) =>
        index % 2 == 0 ? positions[index / 2] : new THREE.Vector3(0, 0, 0)
    );
    linesArray = linesArray.map((vector, index) =>
      index % 2 == 0
        ? vector
        : new THREE.Vector3(
            linesArray[index - 1].x +
              (linesArray[index + 1].x - linesArray[index - 1].x) / 2,
            linesArray[index - 1].y,
            0
          )
    );
    return linesArray;
  };

  initLines = () => {
    // Convert Vector3 array to flat array of positions
    const positions = this.linesArray.reduce((acc: number[], vec) => {
      acc.push(vec.x, vec.y, vec.z);
      return acc;
    }, []);

    this.lineGeometry = new LineGeometry();
    this.lineGeometry.setPositions(positions);

    this.lineMaterial = new LineMaterial({
      color: 0x000000,
      linewidth: 1, // Width can be modified
      resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
      dashed: false
    });

    return new Line2(this.lineGeometry, this.lineMaterial);
  };

  setIndex = (index: number) => {
    this.active = index;
    this.activePosition = this.meshes[index].position;
  };

  initArrow = () => {
    const path = calculatePath(this.activePosition, this.options.targetPoint);
    const positions = path.reduce((acc: number[], vec) => {
      acc.push(vec.x, vec.y, vec.z);
      return acc;
    }, []);

    this.arrowGeometry = new LineGeometry();
    this.arrowGeometry.setPositions(positions);

    this.arrowMaterial = new LineMaterial({
      color: 0xab0000,
      linewidth: 2,
      resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
      dashed: false
    });

    return new Line2(this.arrowGeometry, this.arrowMaterial);
  };

  updateArrow = () => {
    if (this.arrowGeometry) {
      const path = calculatePath(this.activePosition, this.options.targetPoint);
      const positions = path.reduce((acc: number[], vec) => {
        acc.push(vec.x, vec.y, vec.z);
        return acc;
      }, []);
      this.arrowGeometry.setPositions(positions);
    }
  };

  updateLinePositions = () => {
    if (this.lineGeometry) {
      const positions = this.calculateLinesArray(
        this.meshes.map((x) => x.position)
      ).reduce((acc: number[], vec) => {
        acc.push(vec.x, vec.y, vec.z);
        return acc;
      }, []);
      this.lineGeometry.setPositions(positions);
    }
  };

  calculateMovement = (raycaster: THREE.Raycaster) => {
    this.time += this.noiseSpeed;

    this.meshes.forEach((x, index) => {
      const projectedMouse = projectMouse(raycaster, 0);
      const originalPosition = new THREE.Vector3(
        this.positions[index].x,
        this.positions[index].y,
        this.positions[index].z
      );

      // Generate unique noise values for each mesh
      const noiseX = this.noise3D(
        originalPosition.x * 0.5,
        originalPosition.y * 0.5,
        this.time
      ) * this.noiseScale;
      
      const noiseY = this.noise3D(
        originalPosition.x * 0.5,
        originalPosition.y * 0.5,
        this.time + 100 // Offset to create different pattern
      ) * this.noiseScale;

      // Add noise to original position
      const noisyPosition = new THREE.Vector3(
        originalPosition.x + noiseX,
        originalPosition.y + noiseY,
        originalPosition.z
      );

      const material = x.material as THREE.ShaderMaterial;
      const dist = originalPosition.distanceTo(projectedMouse);
      
      if (dist < 0.3) {
        this.setIndex(index);
        x.position.lerp(
          new THREE.Vector3(projectedMouse.x, projectedMouse.y, 0),
          0.05
        );
      } else {
        // Lerp to noisy position instead of original
        x.position.lerp(noisyPosition, 0.01);
      }

      if (this.active == index) {
        material.uniforms.uActive.value = 1.0;
      } else {
        material.uniforms.uActive.value = 0;
      }
      this.updateArrow();

      const text = this.text[index];
      text.position.x = x.position.x + this.textAdjustment;
      text.position.y = x.position.y - this.textAdjustment;
    });
    this.updateLinePositions();
  };

  addToScene(scene: THREE.Scene) {

    const geometry = new THREE.BufferGeometry();
    const vertices = [
      new THREE.Vector3(0, 0.0375, 0),   // Vertex 1 (top of the triangle)
      new THREE.Vector3( 0, 0, 0), // Vertex 2 (bottom left)
      new THREE.Vector3(0.0375, 0, 0)   // Vertex 3 (bottom right)
    ];
    geometry.setFromPoints(vertices)
    const mesh = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({color: 0xab0000}));
    mesh.position.set(this.options.targetPoint.x, this.options.targetPoint.y, 0);
    //scene.add(mesh);

    // Update materials on window resize to maintain consistent line width
    window.addEventListener('resize', () => {
      if (this.lineMaterial) {
        this.lineMaterial.resolution.set(window.innerWidth, window.innerHeight);
      }
      if (this.arrowMaterial) {
        this.arrowMaterial.resolution.set(window.innerWidth, window.innerHeight);
      }
    });

    this.text.forEach((x) => scene.add(x));
    this.meshes.forEach((x) => scene.add(x));
    if (this.lines) scene.add(this.lines);
    if (this.arrow) scene.add(this.arrow);
  }
}