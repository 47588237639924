"use client";
import React, { useEffect, useState } from "react";
import { z } from "zod";
import validator from "validator";
import classNames from "classnames";
import CTAButton from "@/components/CTAButton";
import { ValueProposition } from "@/components/ValueProposition";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckBox, Input, TextArea } from "./formComponents";
import { TypedObject } from "sanity";
import {
  formAbandonmentEvent,
  formSubmissionEvent,
} from "@/components/ContactForm/formEvents";

const CONTACT_FORM_PROPERTIES = {
  formName: "contact name",
  formVersion: 1,
};

export interface IFormProps {
  formIntro: string;
  firstName: string;
  lastName: string;
  business: string;
  email: string;
  message: string;
  consentMessageRaw: TypedObject | TypedObject[];
  className: string;
}

export type FormFields = z.infer<typeof formSchema>;
// Define the schema
const formSchema = z.object({
  firstName: z.string().min(1, "First name is required."),
  lastName: z.string().min(1, "Last name is required."),
  business: z.string().min(1, "Business is required."),
  email: z.string().refine((email) => validator.isEmail(email), {
    message: "Invalid email address",
  }),
  message: z.string().min(1, "Message is required"),
  formConsent: z.number().min(1).max(1, {
    message: "Please agree to the Littleshovels privacy policy.",
  }),
});

export default function ContactForm({
  className,
  formIntro,
  firstName,
  lastName,
  email,
  business,
  message,
  consentMessageRaw,
}: IFormProps) {
  const [submitted, setSubmitted] = useState(false);
  const [formDirty, setFormDirty] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      business: "",
      email: "",
      message: "",
      formConsent: 0,
    },
  });
  const formValues = watch();

  useEffect(() => {
    const hasStartedFilling = Object.values(formValues).some(
      (value) => typeof value === "string" || typeof value === "number"
    );
    if (hasStartedFilling) setFormDirty(true);
  }, [formValues]);

  useEffect(() => {
    const 
      handleUnload = (event: BeforeUnloadEvent) => {
        formAbandonmentEvent(watch(), CONTACT_FORM_PROPERTIES);
      };
      
      window.addEventListener("beforeunload", handleUnload);

      return () => {
        if (handleUnload) {
          window.removeEventListener("beforeunload", handleUnload);
        }
      };
  }, [formDirty, watch]);

  const onSubmit = async (data: any) => {
    console.log("calling on submit");
    try {
      console.log("Starting fetch to /api/submitForm");
      const response = await fetch("/api/submitForm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      console.log("API Response:", response);

      if (response.ok) {
        const responseData = await response.json();
        console.log("Response data:", responseData);
        reset();
        setFormDirty(false);
        setSubmitted(true);
        formSubmissionEvent(CONTACT_FORM_PROPERTIES);
      } else {
        const errorText = await response.text();
        console.error("Error response:", {
          status: response.status,
          statusText: response.statusText,
          body: errorText,
        });
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  return (
    <div
      className={classNames(
        className,
        "bg-netural max-h-screen" // lg:border-black border-l-[0.5px]"
      )}
    >
      {!submitted && (
        <form
          aria-label="Contact Form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)(e);
          }}
          className={classNames(
            "w-full h-full lg:px-0 px-base",
            "flex flex-col relative",
            "lg:text-3xl md:text-xl text-lg"
          )}
        >
          <div className="flex h-fit relative text-balance border-b-black border-b-[0.5px] md:py-base pt-small pb-1 font-light">
            <span
              id="form-title"
              className="flex lg:text-xl md:text-lg text-base w-full"
            >
              {formIntro}
            </span>
          </div>
          <Input
            name="firstName"
            control={control}
            placeholder={firstName}
            type="text"
            autoComplete="given-name"
          />
          <Input
            name="lastName"
            control={control}
            placeholder={lastName}
            type="text"
            autoComplete="family-name"
          />
          <Input
            name="business"
            control={control}
            placeholder={business}
            type="text"
            autoComplete="organization"
          />
          <Input
            name="email"
            control={control}
            placeholder={email}
            type="text"
            autoComplete="email"
          />
          <TextArea name="message" control={control} placeholder={message} />
          <CheckBox
            label={consentMessageRaw}
            control={control}
            name={"formConsent"}
          />
          <button
            aria-disabled={isSubmitting}
            type="submit"
            className={classNames("w-full flex h-full md:max-h-32 max-h-24", {
              "pointer-events-none": isSubmitting,
            })}
          >
            <CTAButton
              onClick={() => {}}
              text="Submit"
              className="w-full h-full flex flex-row !mt-auto !pl-0"
            />
          </button>
        </form>
      )}
      {submitted && (
        <div className="flex relative h-screen">
          <ValueProposition
            text={`Form\nSubmitted!`}
            className="top-small left-4 text-primary"
          />
          <CTAButton
            className="mt-auto w-full border-black border-t-[0.5px]"
            text={`Make another\nenquiry`}
          />
        </div>
      )}
    </div>
  );
}
