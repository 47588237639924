"use client";
import * as THREE from "three";
import { BreakpointObject, BreakpointProperties, TargetPoint } from "./types";

// Constants
const BASE_PARTICLE_DENSITY = 1000;
export const DEBUG_CLEAR_COLOR = "#FFF";
export const BREAKPOINT_PROPERTIES: BreakpointObject<BreakpointProperties> = {
  xl: {
    particleDensity: BASE_PARTICLE_DENSITY * 0.75,
    horizontalTileSpacing: 0.5,
    verticalTileSpacing: 0.3,
    tilesOffset: 0.3,
    verticalOffset: 0,
    tileScale: 1,
    targetPoint: "center",
  },
  lg: {
    particleDensity: BASE_PARTICLE_DENSITY * 0.5,
    horizontalTileSpacing: 0.5,
    verticalTileSpacing: 0.2,
    tilesOffset: 0.3,
    verticalOffset: -0.2,
    tileScale: 1,
    targetPoint: "edgeTablet",
  },
  md: {
    particleDensity: BASE_PARTICLE_DENSITY * 0.5,
    horizontalTileSpacing: 0.7,
    verticalTileSpacing: 0.2,
    tilesOffset: 0,
    verticalOffset: 0,
    tileScale: 1,
    targetPoint: "edgeTablet",
  },
  sm: {
    particleDensity: BASE_PARTICLE_DENSITY * 0.2,
    horizontalTileSpacing: 0.6,
    verticalTileSpacing: 0.3,
    tilesOffset: -0.2,
    verticalOffset: 0,
    tileScale: 1.4,
    targetPoint: "edgeMobile",
  },
};
export const TARGET_CORRECTION: Record<TargetPoint, THREE.Vector3> = {
  center: new THREE.Vector3(0.005, 0, 0),
  edgeTablet: new THREE.Vector3(0, -0.01, 0),
  edgeMobile: new THREE.Vector3(0.005, -0.01, 0),
  edgePro: new THREE.Vector3(0, 0, 0),
};
