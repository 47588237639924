import { PortableText } from "next-sanity";
import { Controller } from "react-hook-form";
import { TypedObject } from "sanity";

interface InputProps extends IFormElemProps {
  type: "text" | "email";
}

interface IFormElemProps {
  name: string;
  control: any; // Replace `any` with the specific type if you have one
  placeholder: string;
}

export const Input: React.FC<InputProps & {
  required?: boolean;
  autoComplete: string;
}> = ({
  name,
  autoComplete,
  control,
  placeholder,
  type,
  required = false,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <div className="flex flex-col relative border-b-black border-b-[0.5px]">
        <label 
          htmlFor={name}
          className="mb-1 sr-only"
        >
          {placeholder}
          {required && <span aria-hidden="true">*</span>}
        </label>
        <input
          autoComplete={autoComplete}
          {...field}
          id={name}
          type={type}
          placeholder={placeholder}
          aria-required={required}
          aria-invalid={!!fieldState.error}
          aria-describedby={fieldState.error ? `${name}-error` : undefined}
          className="py-1 leading-[1.2] flex mt-auto w-full relative outline-none bg-transparent focus:bg-transparent active:bg-transparent"
        />
        {fieldState.error && (
          <Error 
            type={name} 
            error={fieldState.error.message}
          />
        )}
      </div>
    )}
  />
);

export const TextArea: React.FC<IFormElemProps & {
  required?: boolean;
}> = ({
  name,
  control,
  placeholder,
  required = false,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <div className="flex flex-col relative border-b-black border-b-[0.5px] h-full min-h-72 py-1">
        <label 
          htmlFor={name}
          className="mb-1 sr-only"
        >
          {placeholder}
          {required && <span aria-hidden="true">*</span>}
        </label>
        <textarea
          {...field}
          id={name}
          placeholder={placeholder}
          aria-required={required}
          aria-invalid={!!fieldState.error}
          aria-describedby={fieldState.error ? `${name}-error` : undefined}
          className="flex relative w-full h-full placeholder:absolute placeholder:bottom-small outline-none bg-transparent focus:bg-transparent active:bg-transparent min-h-72"
          rows={5}
        />
        {fieldState.error && (
          <Error 
            type={name}
            error={fieldState.error.message}
          />
        )}
      </div>
    )}
  />
);

export const CheckBox = ({
  name,
  label,
  control,
}: {
  name: string;
  label: TypedObject | TypedObject[];
  control: any;
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <div className="relative flex flex-row text-base w-full font-light py-small">
        <input
          checked={field.value === 1} // Checked if the value is 1
          onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
          id={name}
          type="checkbox"
          aria-required="true"
          aria-checked={field.value === 1}
          aria-invalid={!!fieldState.error}
          aria-describedby={fieldState.error ? "checkbox-error" : undefined}
          aria-labelledby="checkbox-label"
          className="mr-4 appearance-none lg:w-6 lg:h-6 w-4 h-4 border border-black  checked:border-none
          checked:bg-[url('/assets/Placeholder-logo.svg')] checked:bg-contain checked:before:absolute checked:before:text-white checked:before:text-base checked:before:font-bold
          checked:before:leading-tight checked:before:ml-[2px]"
        />
        <label id="checkbox-label" htmlFor={name} className="lg:text-base text-sm my-auto">
          {" "}
          <PortableText
            value={label}
            components={{
              marks: {
                link: ({ value, children }) => (
                  <a
                    className="cursor-pointer text-primary"
                    href={value.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {children}
                  </a>
                ),
              },
            }}
          />
        </label>
        {fieldState.error && (
          <Error type="checkbox" error={fieldState.error.message} />
        )}
      </div>
    )}
  />
);

const Error: React.FC<{ error?: string; type: string }> = ({ error, type }) => (
  <p
    id={`${type}-error`}
    role="alert"
    className="text-red-500 text-sm whitespace-nowrap w-fit absolute top-small right-4"
  >
    {error}
  </p>
);
