"use client";
import * as THREE from "three";
import {
  getThreeJSComponents,
  htmlToScenePosition
} from "@/utils/threeJS";
import InteractiveTiles from "./InteractiveTiles";
import generateParticles from "./generateParticles";
import { Dimensions, BreakpointProperties } from "./types";
import {
  DEBUG_CLEAR_COLOR,
  TARGET_CORRECTION
} from "./consts";

const getOptimalPixelRatio = () => {
  const dpr = window.devicePixelRatio;
  // Cap at 2 for optimal mobile performance
  return Math.min(dpr, 2);
};

// Scene Configuration
export const configureScene = (dimensions: Dimensions) => {
  const sizes = {
    width: dimensions.width,
    height: dimensions.height,
    pixelRatio: getOptimalPixelRatio(),
  };

  const aspect = sizes.width / sizes.height;
  const zPos = sizes.height > sizes.width ? 6 : 4;
  const cameraPos = new THREE.Vector3(0, 0, zPos);

  const { scene, camera, renderer } = getThreeJSComponents({
    width: sizes.width,
    height: sizes.height,
    aspectRatio: aspect,
    cameraType: "perspective",
    fov: 30,
    near: 0.1,
    far: 1000,
    backgroundColor: DEBUG_CLEAR_COLOR,
    backgroundOpacity: 0,
    enableControls: false,
  });

  camera.position.copy(cameraPos);
  camera.lookAt(new THREE.Vector3(0, 0, 0));
  renderer.setPixelRatio(2);

  return { scene, camera, renderer, sizes, aspect, cameraPos };
};
// Particles Setup
export const setupParticles = (
  edges: THREE.Vector3[],
  renderer: THREE.WebGLRenderer,
  sizes: { width: number; height: number; pixelRatio: number; },
  particleDensity: number
) => {
  const particlesWidth = Math.abs(edges[0].x) * 2.1;
  return generateParticles(particlesWidth, renderer, sizes, particleDensity);
};
// Tiles Setup
export const setupTiles = (
  services: any[],
  edges: THREE.Vector3[],
  camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
  container: HTMLDivElement,
  sizes: { width: number; height: number; },
  aspect: number,
  breakPointProperty: BreakpointProperties,
  serviceIndex: number
) => {
  const tileSpacing = Math.abs(edges[0].x) * 2;
  // eslint-disable-next-line
  const targetPoint = (document.querySelector(".arrowTarget")?.getBoundingClientRect())!;
  const containerRect = container.getBoundingClientRect();

  const targetX = targetPoint.left - containerRect.left;
  const targetY = targetPoint.top - containerRect.top;

  const targetCoords = htmlToScenePosition(
    targetX,
    targetY,
    sizes.width,
    sizes.height,
    camera
  );

  return new InteractiveTiles({
    tiles: services,
    tilesPositions: services.map(
      (_, index) => new THREE.Vector3(
        (index * (tileSpacing / (services.length - 1)) - tileSpacing / 2) *
        breakPointProperty.horizontalTileSpacing +
        breakPointProperty.tilesOffset,
        Math.sin(index * 2) * breakPointProperty.verticalTileSpacing +
        breakPointProperty.verticalOffset,
        0
      )
    ),
    offset: 0,
    serviceIndex,
    renderLines: aspect > 1,
    tileScale: breakPointProperty.tileScale,
    targetPoint: targetCoords.add(TARGET_CORRECTION[breakPointProperty.targetPoint]),
  });
};
