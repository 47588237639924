"use client";

import { ValueProposition } from "@/components/ValueProposition";
import { useRef, useState } from "react";
import { ServicesInfo } from "@/components/DualColumn";
import ServicesScene from "./ServicesScene";
import classNames from "classnames";
import { TypedObject } from "sanity";

interface IInteractiveSection {
  valueProposition: string;
  servicesInfo: {
    tiles: {
      title: string;
      textRaw: TypedObject | TypedObject[];
    }[];
    ctaSection: {
      href: string;
      text: string;
    };
  };
}

const InteractiveSection = (props: IInteractiveSection) => {
  const [currentService, setCurrentService] = useState(0);
  const currentServiceRef = useRef(0);
  const updateService = (index: number) => {
    currentServiceRef.current = index;
    setCurrentService(index);
  };

  return (
    <div
      className={classNames(
        "flex relative mt-auto col-span-12 xl:mb-0 mb-24",
        "lg:h-full",
      )}
    >
      <div className="h-full mt-auto flex flex-col w-full">
        <div className="flex relative xl:h-[55vh] lg:h-[65vh] md:h-[60vh] h-[80vh] lg:mt-0 mt-24">
          <div className="flex w-full h-fit lg:my-auto pointer-events-none z-10">
            <ValueProposition
              className="flex 4xl:mt-auto xl:mb-0 mb-auto text-primary md:absolute !relative md:py-0"
              text={props.valueProposition}
            />
          </div>
          <div
            id="section-two-anim"
            className="absolute flex w-screen -ml-4 h-full bottom-0 [&>*]:mt-auto [&>*]:h-4/5 xl:[&>*]:mb-0  md:[&>*]:-mb-24"// [&>*]:-mb-12" //[&>*]:h-[40vh]
          >
            <div className="absolute top-0 left-0 right-0 w-full md:!h-1/2 z-2 !h-2/5 xl:hidden"/>
            <ServicesScene
              updateService={updateService}
              services={props.servicesInfo.tiles.map((x) => x.title)}
              currentIndex={currentService}
              indexRef={currentServiceRef}
            />
            <div className="absolute  bottom-0 w-full !h-1/5 z-20 xl:hidden"/>
          </div>
        </div>
        <div
          className={classNames(
            "flex relative",
            "xl:h-[30vh] xl:ml-auto xl:w-1/2",
            "lg:w-3/4",
            "h-fit min-h-64"
          )}
        >
          <ServicesInfo
            className="h-full lg:flex-row flex flex-col relative pb-small xl:pb-small lg:-mb-24"
            currentIndex={currentService}
            services={props.servicesInfo.tiles}
            CTA={props.servicesInfo.ctaSection}
            setIndex={updateService}
          />
        </div>
      </div>
    </div>
  );
};
export default InteractiveSection;
