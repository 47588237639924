import classNames from "classnames";
import Image from "next/image";

const SiteLogo = ({ className, logoSubtext }: { className?: string, logoSubtext:string }) => {
  return (
    <div
      className={classNames(
        className,
        "flex flex-col w-full md:pt-0"
      )}
    >
      <div className="flex flex-row h-fit w-fit pl-base pt-small">
        <div
          className={classNames(
            "flex relative",
            "2xl:text-7xl 2xl:leading-[0.75]", // 2xl:pt-16",
            "md:text-6xl", // md:pt-8",
            "sm:text-[144px] text-[36vw] !leading-[0.8] mb-small",
            "tracking-[-0.05em] w-fit translate-x-[-0.08em] font-[550]"
          )}
        >
          <span className="text-primary">LS</span>
          <span className="text-black">D</span>
        </div>
        <div
          className={classNames(
            "flex",
            "2xl:w-32 2xl:h-32",
            "md:w-24 md:h-24",
            "h-16 w-16"
          )}
        >
          <Image
            width={420}
            height={420}
            src={"assets/Placeholder-logo.svg"}
            alt="Littleshovels logo"
            className="flex"
          />
        </div>
      </div>
      <span
        className={classNames(
          "md:text-md",
          "text-base leading-[1] px-base"
        )}
      >
       {logoSubtext}
      </span>
    </div>
  );
};

export default SiteLogo;
