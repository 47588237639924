"use client";

import * as THREE from "three";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { getThreeJSComponents } from "@/utils/threeJS";
import { debounce } from "@/utils/utils";

const NUM_SQUARES = 100;
const NUM_POINTS = 400;

// Memoize math functions to avoid repeated lookups
const { abs, pow, cos, sin, PI } = Math;
const TWO_PI = 2 * PI;

// Pre-calculate constants
const ROTATION_INCREMENT = PI / NUM_SQUARES;
const POINTS_MULTIPLIER = TWO_PI / (NUM_POINTS - 1);

const superShape = (a: number, b: number, m: number, n1: number, n2: number, n3: number) => {
  // Pre-calculate constant multipliers
  const mDiv4 = m / 4;
  
  return (phi: number) => {
    const cosPhiM = cos(phi * mDiv4);
    const sinPhiM = sin(phi * mDiv4);

    const t1 = pow(abs(cosPhiM / a), n2);
    const t2 = pow(abs(sinPhiM / b), n3);
    const r = pow(t1 + t2, 1 / n1);

    if (abs(r) === 0) return [0, 0];
    
    const rInv = 1 / r;
    return [rInv * cos(phi), rInv * sin(phi)];
  };
};

const LandingAnimation = ({ fullScreen }: { fullScreen?: boolean }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene>();
  const cameraRef = useRef<THREE.PerspectiveCamera | THREE.OrthographicCamera>();
  const rendererRef = useRef<THREE.WebGLRenderer>();
  const shapeRef = useRef<THREE.Group>();
  const materialsRef = useRef<LineMaterial[]>([]);
  const linesRef = useRef<Line2[]>([]);

  const mouseScrollDelta = useRef({ x: 0, y: 0 });
  const animationFrameId = useRef<number>();
  const isAnimating = useRef(false);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Memoize vertices calculation
  const vertices = useMemo(() => {
    const sShape = superShape(4, 8, 4, 12, 15, 15);
    const verticesArray = new Float32Array(NUM_POINTS * 3);
    
    for (let i = 0; i < NUM_POINTS; i++) {
      const [x, y] = sShape(i * POINTS_MULTIPLIER);
      const idx = i * 3;
      verticesArray[idx] = x;
      verticesArray[idx + 1] = y;
      verticesArray[idx + 2] = 0;
    }
    
    return verticesArray;
  }, []);

  // Memoize geometry
  const lineGeometry = useMemo(() => {
    const geometry = new LineGeometry();
    geometry.setPositions(vertices);
    return geometry;
  }, [vertices]);

  // Memoize materials and lines creation
  const createLinesWithMaterials = useCallback((width: number, height: number) => {
    // Clear previous materials and lines if they exist
    materialsRef.current.forEach(material => material.dispose());
    materialsRef.current = [];
    linesRef.current = [];

    for (let i = 0; i < NUM_SQUARES; i++) {
      const lineMaterial = new LineMaterial({
        color: (i + 12) % 25 === 0 ? "#000" : 0xa0a0a0,
        linewidth: 0.3,
        opacity: 1,
        resolution: new THREE.Vector2(width, height),
      });
      materialsRef.current.push(lineMaterial);

      const line = new Line2(lineGeometry, lineMaterial);
      line.computeLineDistances();
      line.rotation.z = i * ROTATION_INCREMENT * 0.4;
      line.position.z = (i * 6) / NUM_SQUARES - 2;
      linesRef.current.push(line);
    }
  }, [lineGeometry]);

  // Handle window resize
  const handleResize = useCallback(() => {
    if (!containerRef.current || !rendererRef.current || !cameraRef.current) return;

    const width = fullScreen ? window.innerWidth : containerRef.current.clientWidth;
    const height = containerRef.current.clientHeight;

    if (cameraRef.current instanceof THREE.PerspectiveCamera) {
      cameraRef.current.aspect = width / height;
      cameraRef.current.updateProjectionMatrix();
    }

    rendererRef.current.setSize(width, height);

    // Update material resolutions
    materialsRef.current.forEach(material => {
      material.resolution.set(width, height);
      material.needsUpdate = true;
    });

    setDimensions({ width, height });
  }, [fullScreen]);

  // Memoize animation function
  const animate = useCallback((currentTime: number) => {
    if (!isAnimating.current || !shapeRef.current || !cameraRef.current || !rendererRef.current) return;
    
    mouseScrollDelta.current.y *= 0.99;

    const shape = shapeRef.current;
    const scrollY = mouseScrollDelta.current.y;
    
    shape.rotation.z += scrollY * 0.01;
    shape.rotation.y += scrollY * 0.005;
    shape.rotation.x += scrollY * 0.0005;

    const timeScale = (currentTime / 12000) * TWO_PI;
    cameraRef.current.rotation.y = cos(timeScale) * 0.1;

    rendererRef.current.render(sceneRef.current!, cameraRef.current);
    animationFrameId.current = requestAnimationFrame(animate);
  }, []);

  // Handle scroll events
  const handleScroll = useCallback((e: WheelEvent) => {
    const scrollAmt = 1.;
    const increment = e.deltaY > 0 ? scrollAmt : -scrollAmt;
    mouseScrollDelta.current = { x: e.deltaX, y: increment };
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;

    const width = fullScreen ? window.innerWidth : containerRef.current.clientWidth;
    const height = containerRef.current.clientHeight;

    // Initialize Three.js components
    const { scene, camera, renderer } = getThreeJSComponents({
      width,
      height,
      aspectRatio: width / height,
      cameraType: "perspective",
      fov: 100,
      near: 0.1,
      far: 500,
      backgroundColor: "#FFF",
      backgroundOpacity: 1,
      enableControls: false,
    });

    sceneRef.current = scene;
    cameraRef.current = camera;
    rendererRef.current = renderer;

    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    camera.position.z = 2;

    // Create shape and add lines
    const shape = new THREE.Group();
    createLinesWithMaterials(width, height);
    linesRef.current.forEach(line => shape.add(line));
    
    shapeRef.current = shape;
    scene.add(shape);

    // Initial shape positioning
    shape.position.set(-3.48, 0, -1);
    shape.rotation.set(0.59, 0.67, -1.62);

    containerRef.current.appendChild(renderer.domElement);

    // Set up event listeners
    const debouncedResize = debounce(handleResize, 200);
    const debouncedScroll = debounce(handleScroll, 16);

    window.addEventListener("resize", debouncedResize);
    window.addEventListener("wheel", debouncedScroll);

    // Set up intersection observer
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !isAnimating.current) {
          isAnimating.current = true;
          animate(0);
        } else if (!entry.isIntersecting && isAnimating.current) {
          isAnimating.current = false;
          if (animationFrameId.current) {
            cancelAnimationFrame(animationFrameId.current);
            animationFrameId.current = undefined;
          }
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    // Cleanup function
    return () => {
      isAnimating.current = false;
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }

      // Dispose of geometries and materials
      if (lineGeometry) {
        lineGeometry.dispose();
      }

      materialsRef.current.forEach(material => material.dispose());
      materialsRef.current = [];
      linesRef.current = [];

      // Clean up scene
      scene.traverse(object => {
        if (object instanceof THREE.Mesh) {
          if (object.geometry) object.geometry.dispose();
          if (object.material) {
            if (Array.isArray(object.material)) {
              object.material.forEach(material => material.dispose());
            } else {
              object.material.dispose();
            }
          }
        }
      });

      renderer.dispose();
      observer.disconnect();

      // Remove event listeners
      window.removeEventListener("resize", debouncedResize);
      window.removeEventListener("wheel", debouncedScroll);

      if (containerRef.current && renderer.domElement) {
        containerRef.current.removeChild(renderer.domElement);
      }
    };
  }, [dimensions, createLinesWithMaterials, fullScreen, animate, handleResize, handleScroll, lineGeometry]);

  return <div aria-hidden="true" className="flex bg-neutral h-full" ref={containerRef} />;
};

export default LandingAnimation;