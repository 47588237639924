import { Breakpoint } from "@/sections/servicesSection/ServicesScene/src/types";
import * as THREE from "three";

export const calculatePath = (
  start: THREE.Vector3,
  end: THREE.Vector3
): THREE.Vector3[] => {
  const zOffset = -0.01;
  const yDistance = end.y - start.y;
  const verticalSegmentHeight = Math.abs(yDistance) * 0.3; // 30% of total height

  const firstBendY = yDistance > 0
    ? start.y + verticalSegmentHeight
    : start.y - verticalSegmentHeight;

  const secondBendY = yDistance > 0
    ? end.y - verticalSegmentHeight
    : end.y + verticalSegmentHeight;

  const path = [
    new THREE.Vector3(start.x, start.y, zOffset),
    new THREE.Vector3(start.x, firstBendY, zOffset),
    new THREE.Vector3(end.x, secondBendY, zOffset),
    new THREE.Vector3(end.x, end.y, zOffset)
  ];

  return path;
}
export function getCanvasEdges(
  renderer: THREE.WebGLRenderer,
  camera: THREE.PerspectiveCamera,
  scene: THREE.Scene,
  depth: number
) {
  // Set the camera to look at the origin (or your target point)
  const halfHeight = Math.tan(THREE.MathUtils.degToRad(camera.fov) / 2) * depth;
  const halfWidth = halfHeight * camera.aspect;

  const corners = [
    new THREE.Vector3(-halfWidth, halfHeight, -depth), // top-left
    new THREE.Vector3(halfWidth, halfHeight, -depth), // top-right
    new THREE.Vector3(halfWidth, -halfHeight, -depth), // bottom-right
    new THREE.Vector3(-halfWidth, -halfHeight, -depth), // bottom-left
  ];

  // Convert corners to world coordinates
  corners.forEach((corner) => corner.applyMatrix4(camera.matrixWorld));
  // addTestShapes(corners, scene);
  return corners;
}
function addTestShapes(cornerVectors: THREE.Vector3[], scene: THREE.Scene) {
  const geometry = new THREE.SphereGeometry(0.05, 8, 8); // Small sphere
  const material = new THREE.MeshBasicMaterial({ color: 65280 }); // Green color

  cornerVectors.forEach((corner) => {
    const shape = new THREE.Mesh(geometry, material);
    shape.position.copy(new THREE.Vector3(corner.x, corner.y, corner.z)); // Set the position to the corner vector
    scene.add(shape); // Add the shape to the scene
  });
}
export const getBreakpoint = (): Breakpoint => {
  const width = window.innerWidth;
  if (width < 768) return "sm";
  if (width < 1024) return "md";
  if (width < 1280) return "lg";
  return "xl";
};

