"use client";
import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

const DURATION = 0.5;
const STAGGER = 0.025;

const FlipHover = ({
  children,
  hovered,
  invert,
  className,
}: {
  children: string;
  hovered?: boolean;
  invert?: boolean;
  className?:string;
}) => {
  const text = children; //"Flip Hover text";
  return (
    <motion.div
      initial="initial"
      className={classNames(className, "relative block whitespace-nowrap overflow-hidden")}
      style={{
        lineHeight: 1,
      }}
      transition={{ duration: DURATION }}
    >
      <div>
        {Array.from(text).map((c, i) => (
          <motion.span
            className="inline-block"
            initial="initial"
            transition={{
              delay: i * STAGGER,
              duration: DURATION,
              ease: "easeInOut",
            }}
            animate={hovered ? "hovered" : "initial"}
            variants={{
              initial: {
                y: invert ? "-100%" : 0,
              },
              hovered: {
                y: invert ? 0 : "-100%",
              },
            }}
            key={`${c}_${i}`}
          >
            {c === " " ? <span>&nbsp;</span> : c}
          </motion.span>
        ))}
      </div>
      <div className="absolute inset-0">
        {text.split("").map((c, i) => (
          <motion.span
            transition={{
              delay: i * STAGGER,
              duration: DURATION,
              ease: "easeInOut",
            }}
            className="inline-block"
            initial="initial"
            animate={hovered ? "hovered" : "initial"}
            variants={{
              initial: {
                y: invert ? 0 : "100%",
              },
              hovered: {
                y: invert ? "100%" : 0,
              },
            }}
            key={`${c}_${i}_2`}
          >
            {c === " " ? <span>&nbsp;</span> : c}
          </motion.span>
        ))}
      </div>
    </motion.div>
  );
};

export default FlipHover;
