import * as THREE from "three";
import particlesVertexShader from "./shaders/particles/vertex.glsl";
import particlesFragmentShader from "./shaders/particles/fragment.glsl";
import gpgpuParticlesShader from "./shaders/gpgpu/particles.glsl";
import { GPUComputationRenderer } from "three/examples/jsm/misc/GPUComputationRenderer.js";
import { Variable } from "three/examples/jsm/Addons.js";



const generateParticles = (
  particlesWidth: number,
  renderer: THREE.WebGLRenderer,
  sizes: { width: number; height: number; pixelRatio: number },
  particleDensity: number,
) => {
  /**
   * Base Geometry
   */

  type BaseGeometry = {
    instance:
      | THREE.SphereGeometry
      | THREE.PlaneGeometry
      | THREE.BoxGeometry
      | THREE.CylinderGeometry;
    count: number;
  };

  const baseGeometry: BaseGeometry = {
    instance: new THREE.CylinderGeometry(
      0.1, 
      0.1,
      particlesWidth,
      particleDensity, 
      particleDensity,
      true
    ), //new THREE.BoxGeometry(0.75, particlesWidth, 0.75, 100, 1000, 100),/
    count: 0,
  };
  baseGeometry.instance.rotateZ(Math.PI / 2);
  // baseGeometry.instance.translate(-1, 0, 0);

  baseGeometry.count = baseGeometry.instance.attributes.position.count;

  /**
   *  GPU Compute
   */

  type GPGPU = {
    size: number;
    computation: GPUComputationRenderer;
    particlesVariable?: Variable;
    debug?: THREE.Mesh;
  };

  const size = Math.ceil(Math.sqrt(baseGeometry.count));

  const gpgpu: GPGPU = {
    size: size,
    computation: new GPUComputationRenderer(size, size, renderer),
  };

  /**
   * Base particles
   */

  const baseParticlesTexture = gpgpu.computation.createTexture();

  gpgpu.particlesVariable = gpgpu.computation.addVariable(
    "uParticles",
    gpgpuParticlesShader,
    baseParticlesTexture
  );

  gpgpu.particlesVariable.material.uniforms.uTime = new THREE.Uniform(0);
  gpgpu.particlesVariable.material.uniforms.uScale = new THREE.Uniform(
    particlesWidth
  );
  gpgpu.particlesVariable.material.uniforms.uMouse = new THREE.Uniform(
    new THREE.Vector2(10, 10)
  );

  gpgpu.computation.setVariableDependencies(gpgpu.particlesVariable, [
    gpgpu.particlesVariable,
  ]);

  /**
   * Load positions from baseGeometry into baseParticlesTexture
   */

  for (let i = 0; i < baseGeometry.count; i++) {
    const i3 = i * 3;
    const i4 = i * 4;
    // Position based on geometry
    baseParticlesTexture.image.data[i4 + 0] =
      baseGeometry.instance.attributes.position.array[i3 + 0];
    baseParticlesTexture.image.data[i4 + 1] =
      baseGeometry.instance.attributes.position.array[i3 + 1]; // *  y;
    baseParticlesTexture.image.data[i4 + 2] =
      baseGeometry.instance.attributes.position.array[i3 + 2]; // * z;
    baseParticlesTexture.image.data[i4 + 3] = Math.random();
  }

  gpgpu.computation.init();

  /**
   * Particles
   */

  type Particles = {
    points: THREE.Points;
    material: THREE.ShaderMaterial;
    geometry: THREE.BufferGeometry;
  };

  const particlesUvArray = new Float32Array(baseGeometry.count * 2);

  for (let y = 0; y < gpgpu.size; y++) {
    for (let x = 0; x < gpgpu.size; x++) {
      const i = y * gpgpu.size + x;
      const i2 = i * 2;

      // Particles UV
      const uvX = (x + 0.5) / gpgpu.size;
      const uvY = (y + 0.5) / gpgpu.size;

      particlesUvArray[i2 + 0] = uvX;
      particlesUvArray[i2 + 1] = uvY;
    }
  }

  const noiseUvArray = new Float32Array(baseGeometry.count * 4);

  for (let i = 0; i < baseGeometry.count; i++) {
    const i4 = i * 4;

    noiseUvArray[i4 + 0] = Math.random();

    noiseUvArray[i4 + 1] = Math.random();

    noiseUvArray[i4 + 2] = 1;

    noiseUvArray[i4 + 3] = 1;
  }

  const geometry = new THREE.BufferGeometry();
  const material = new THREE.ShaderMaterial({
    vertexShader: particlesVertexShader,
    fragmentShader: particlesFragmentShader,
    uniforms: {
      uSize: new THREE.Uniform(0.0001),
      uResolution: new THREE.Uniform(
        new THREE.Vector2(
          sizes.width * sizes.pixelRatio,
          sizes.height * sizes.pixelRatio
        )
      ),
      uParticlesTexture: new THREE.Uniform(null),
      uBase: new THREE.Uniform(null),
      uTime: new THREE.Uniform(0),
    },
  });
  const particles: Particles = {
    geometry: geometry,
    material: material,
    points: new THREE.Points(geometry, material),
  };

  const uInfo = new THREE.DataTexture(
    noiseUvArray,
    Math.sqrt(baseGeometry.count),
    Math.sqrt(baseGeometry.count), // Height remains 1
    THREE.RGBAFormat,
    THREE.FloatType
  );

  uInfo.wrapS = THREE.RepeatWrapping;
  uInfo.wrapT = THREE.RepeatWrapping;
  uInfo.magFilter = THREE.NearestFilter;
  uInfo.minFilter = THREE.NearestFilter;
  uInfo.needsUpdate = true;

  gpgpu.particlesVariable.material.uniforms.uInfo = new THREE.Uniform(null);

  gpgpu.particlesVariable.material.uniforms.uInfo.value = uInfo;

  gpgpu.particlesVariable.material.uniforms.uBase = new THREE.Uniform(
    baseParticlesTexture
  );

  particles.geometry.setDrawRange(0, baseGeometry.count);

  particles.geometry.setAttribute(
    "aParticlesUv",
    new THREE.BufferAttribute(particlesUvArray, 2)
  );
  return {particles: particles, gpgpu: gpgpu};
};


export default generateParticles;