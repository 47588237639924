// eslint-disable-next-line
type DebouncedFunction<T extends (...args: any[]) => any> = {
  (...args: Parameters<T>): void;
  cancel: () => void;
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): DebouncedFunction<T> {
  let timeoutId: NodeJS.Timeout;

  const debouncedFn = (...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), wait);
  };

  debouncedFn.cancel = () => {
    clearTimeout(timeoutId);
  };

  return debouncedFn;
}

export const sleep = async (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

type ISectionDTO = {
  _id: string;
};

export const structureSingletonQueryResults = <T extends ISectionDTO, U>(
  data: Record<string, T[]>,
  isDraft = false
): U =>
  Object.keys(data).reduce((acc, curr) => {
    const current = isDraft
      ? (data[curr].filter((service) => service._id.startsWith("drafts."))[0] ??
        data[curr][0])
      : data[curr][0];
    return { ...acc, [curr]: current };
  }, {} as U);

  export function splitTextIntoTwoLines(text: string): string {
    // Split into words
    const words = text.trim().split(/\s+/);
    
    // Find the middle index
    const midPoint = Math.ceil(words.length / 2);
    
    // Join the words back for each line
    const firstLine = words.slice(0, midPoint).join(' ');
    const secondLine = words.slice(midPoint).join(' ');
    
    // Calculate padding needed
    const lengthDiff = firstLine.length - secondLine.length;
    const paddingNeeded = lengthDiff >= 0 ? lengthDiff + 1 : 1;
    
    // Pad the second line
    const paddedSecondLine = ' '.repeat(paddingNeeded) + secondLine;
    
    return [firstLine, paddedSecondLine].join('\n');
  }

  